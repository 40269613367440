<template>
  <div class="super">
    <div class="holder">
      <Header />

      <!-- Navbar sections ends -->

      <div class="body-holder">
        <div class="body-wrap">
          <div class="container body-child">
            <div class="card">
              <div class="card-body my-card-body">
                <div class="text-header">
                  <h1>WE WANT TO KNOW YOU MORE</h1>
                </div>
                <form>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control input-bar"
                      id="firstname"
                      placeholder="| Your First Name"
                      v-model="firstname"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control input-bar"
                      id="lastname"
                      placeholder="| Your Last Name "
                      v-model="lastname"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control input-bar"
                      id="othername"
                      placeholder="| Any other name?"
                      v-model="othernames"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control input-bar"
                      id="address"
                      placeholder="| Address"
                      v-model="address"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control input-bar"
                      id="dob"
                      placeholder="Date of birth"
                      v-model="dob"
                      onfocus="(this.type='date')"
                    />
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <button
                        class="btn btn-prefix dropdown-toggle prefix-right"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        +234
                      </button>
                      <div class="dropdown-menu">
                        <a class="dropdown-item" href="#">+90</a>
                        <a class="dropdown-item" href="#">+44</a>
                        <a class="dropdown-item" href="#">+233</a>
                      </div>
                    </div>
                    <input
                      type="text"
                      class="form-control input-bar"
                      aria-label="Text input with dropdown button"
                      v-model="phone"
                    />
                  </div>
                  <div class="form-group">
                    <select
                      v-model="selectedCountry"
                      @change="getCountryId($event)"
                      class="form-control input-bar"
                      id="exampleFormControlSelect1"
                    >
                      <option value="" selected disabled>Select Country</option>
                      <option
                        v-for="(country, index) in getCountries"
                        :key="index"
                        v-bind:value="{ id: country.id, text: country.name }"
                        >{{ country.name }}</option
                      >
                    </select>
                  </div>
                  <div class="form-group">
                    <select
                      v-model="selectedState"
                      @change="
                        getStateId($event);
                        getCity();
                      "
                      class="form-control input-bar"
                      id="exampleFormControlSelect1"
                    >
                      <option value="" selected disabled>Select State</option>
                      <option
                        v-for="(state, index) in getStates"
                        :key="index"
                        v-bind:value="{ id: state.id, text: state.name }"
                        >{{ state.name }}</option
                      >
                    </select>
                  </div>
                  <div id="cityDiv">
                    <div class="form-group">
                      <select
                        v-model="selectedCity"
                        @change="getCity($event)"
                        class="form-control input-bar"
                        id="exampleFormControlSelect2"
                      >
                        <option value="" selected disabled>Select City</option>
                        <option
                          v-for="(city, index) in cities"
                          :key="index"
                          v-bind:value="{ id: city.id, text: city.name }"
                          >{{ city.name }}</option
                        >
                      </select>
                    </div>
                  </div>
                  <div class="radio-div form-group">
                    <div class="radio-btn">
                      <div
                        class="custom-control custom-radio custom-control-inline"
                      >
                        <input
                          v-model="gender"
                          value="male"
                          type="radio"
                          id="male"
                          name="male"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label gender-label"
                          for="male"
                          >Male</label
                        >
                      </div>
                      <div
                        class="custom-control custom-radio custom-control-inline"
                      >
                        <input
                          v-model="gender"
                          value="female"
                          type="radio"
                          id="female"
                          name="female"
                          class="custom-control-input"
                        />
                        <label
                          class="custom-control-label gender-label"
                          for="female"
                          >Female</label
                        >
                      </div>
                    </div>
                  </div>
                  <div v-if="errors.length">
                    <p class="errorHeader">Please correct the error(s):</p>
                    <ul class="error-ul">
                      <li
                        v-for="error in errors"
                        :key="error.error"
                        class="errorMessage"
                      >
                        {{ error }}
                      </li>
                    </ul>
                  </div>
                  <div class="radio-holder"></div>
                  <div class="form-group">
                    <button
                      @click.prevent="profileSetup()"
                      class="btn setup-btn btn-block"
                    >
                      CONTINUE
                      <i
                        class="fa fa-spinner fa-spin ml-3 f-12 btn-loader"
                        v-if="loader"
                      ></i>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="welcome-holder">
            <div class="container card welcome-child">
              <div class="card-body">
                <div class="">
                  <div class="close-welcome-div">
                    <small
                      ><i class="fa fa-times close-welcome-icon"></i
                    ></small>
                  </div>
                  <div class="welcome-body">
                    <p>Welcome</p>
                    <img src="../assets/images/good.svg" alt="welcome" />
                    <p><span>...</span>We got you covered!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterMain />
  </div>
</template>

<script>
import FooterMain from "./FooterMain.vue";
import Header from "./Header";
import { Service } from "../store/service";
const Api = new Service();

export default {
  name: "ProfileSignup",
  components: {
    Header,
    FooterMain
  },
  data() {
    return {
      loader: false,
      errors: [],
      firstname: "",
      lastname: "",
      othernames: "",
      address: "",
      dob: "",
      userId: JSON.parse(localStorage.getItem("shelta-user")),
      // stateid: "",
      // cityid: "",
      gender: "",
      phone: "",
      selectedCountry: "",
      selectedState: "",
      selectedCity: "",
      getCountries: "",
      getStates: "",
      cities: ""
    };
  },
  mounted() {
    Api.getRequest(`fetchallcountries`)
      .then(res => {
        this.$store.commit("setApiWarning", "Loading countries...");
        console.log(res);
        if (res.data.success) {
          this.getCountries = res.data.countries;
          this.$store.commit("setApiSuccess", "Countries loaded");
          this.$store.commit("setApiWarning", "");
        } else if (res.data.error) {
          return this.$store.commit("setApiFailed", res.data.error);
        }
      })
      .catch(err => {
        console.log(err);
      });

    // Api.getRequest(`fetchstates`)
    //   .then((res) => {
    //     this.$store.commit("setApiWarning", "Loading states...");
    //     console.log(res);
    //     if (res.data.success) {
    //       this.getStates = res.data.states;
    //       this.$store.commit("setApiSuccess", "States loaded");
    //       this.$store.commit("setApiWarning", "");
    //     } else if (res.data.error) {
    //       return this.$store.commit("setApiFailed", res.data.error);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  },
  methods: {
    getCountryId: function(event) {
      this.$store.commit("setApiWarning", "Loading cities...");
      Api.getRequest(`fetchstatesbycountryid/${this.selectedCountry.id}`).then(
        res => {
          console.log("Sates:", res);
          this.getStates = res.data.states;
          this.$store.commit("setApiSuccess", "States loaded");
          this.$store.commit("setApiWarning", "");
        }
      );
    },
    getStateId: function(event) {
      this.$store.commit("setApiWarning", "Loading cities...");
      Api.getRequest(`fetchcitiesbystateid/${this.selectedState.id}`).then(
        res => {
          console.log("Cities:", res);
          this.cities = res.data.cities;
          this.$store.commit("setApiSuccess", "Cities loaded");
          this.$store.commit("setApiWarning", "");
        }
      );
    },
    // getCities: function(event) {
    //   this.$store.commit("setApiWarning", "Loading states...");
    //   Api.getRequest(`fetchcities`).then(
    //     (res) => {
    //       console.log("cities:", res);
    //       this.cities = res.data.cities;
    //       this.$store.commit("setApiSuccess", "Cities loaded");
    //       this.$store.commit("setApiWarning", "");
    //     }
    //   );
    // },
    getCity: function(event) {
      const stateCities = this.cities;
      const x = document.getElementById("cityDiv");
      if (!stateCities && stateCities.length != 0) {
        x.style.display = "none";
      } else {
        x.style.display = "block";
      }
      console.log("Hello city");
    },

    profileSetup: function() {
      console.log("Gender", this.gender);
      this.loader = true;
      this.errors = [];
      if (this.firstname === "") {
        this.errors.push("First name is required!");
        this.loader = false;
      }
      if (this.lastname === "") {
        this.errors.push("Last name is required!");
        this.loader = false;
      }
      if (this.othernames === "") {
        this.errors.push("Other name is required!");
        this.loader = false;
      }
      if (this.address === "") {
        this.errors.push("Address is required!");
        this.loader = false;
      }
      if (this.selectedState === "") {
        this.errors.push("Please select a state");
        this.loader = false;
      }
      if (this.selectedCity === "") {
        this.errors.push("Please select a city");
        this.loader = false;
      }
      if (this.dob === "") {
        this.errors.push("Date of birth required");
        this.loader = false;
      }
      if (this.gender === "") {
        this.errors.push("Gender is required");
        this.loader = false;
      } else if (this.phone === "") {
        this.errors.push("Phone is required!");
        this.loader = false;
      } else {
        Api.postRequest(`updateuser`, {
          id: JSON.parse(localStorage.getItem("shelta-user")).id,
          firstname: this.firstname,
          lastname: this.lastname,
          address: this.address,
          dob: this.dob,
          othernames: this.othernames,
          countryid: this.selectedCountry.id,
          stateid: this.selectedState.id,
          cityid: this.selectedCity.id,
          phone: this.phone,
          gender: this.gender
        }).then(res => {
          if (res.data.success) {
            this.loader = false;
            console.log("success res", res);
            localStorage.setItem("shelta-user", JSON.stringify(res.data.user));
            return this.$store.commit("setApiSuccess", res.data.success);
          } else if (res.data.error) {
            this.loader = false;
            return this.$store.commit("setApiFailed", res.data.error);
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
$primary: #0033ea;
$black: #000000;
$fontFamily: url("https://fonts.googleapis.com/css2?family=Gotham:wght@100&display=swap");
$fontFamily2: url("https://fonts.googleapis.com/css2?family=Gotham-Ultra:wght@100&display=swap");

.super {
  flex-direction: column;
  display: flex;
  min-height: 100vh;
}
.holder {
  flex-grow: 1;
  min-height: 100%;
}

.body-holder {
  padding-top: 3rem;
  padding-bottom: 6rem;
}
.logo-div {
  background: $primary;
  // clip-path: circle(50% at 50% 0);
  clip-path: circle(50% at 50% -20%);
  margin: auto;
  width: 12rem;
  height: 7rem;
  height: 4rem;
  position: relative;
}
.logo-div > img {
  width: 4.5rem;
  position: relative;
  top: 23%;
}

.body-child {
  // width: 35.46rem;
  min-height: auto;
  margin: auto;
  padding-top: 7rem;
  // display: none;
}
.my-card-body {
  border: 0.2px solid #3cc6f5;
  box-sizing: border-box;
}
.text-header > h1 {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 40px;
  letter-spacing: -0.05em;
  text-transform: uppercase;
  color: $primary;
}

.input-bar {
  border-radius: 0;
  background: #ffffff;
  height: 44px;
  border: 0.5px solid #445db6;
  // border: 0.2px solid #a2b0e3;
  box-sizing: border-box;
  box-shadow: none;

  align-content: normal;
  font-family: $fontFamily;
  font-style: normal;
  // font-weight: 300;
  font-size: 14px;
  line-height: 25px;
  letter-spacing: -0.05em;
  // text-decoration-line: underline;
  color: rgba(0, 0, 0, 0.5);
}

.gender-label {
  font-family: $fontFamily;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.5);
}
.select-bar {
  height: 50px !important;
}
.btn-prefix {
  color: #495057;
  box-shadow: none !important;
  border-color: #445db6 !important;
}
.btn-prefix:hover {
  background: $primary !important;
  color: #ffffff;
}
.setup-btn:hover {
  opacity: 0.6;
}
.setup-btn:active {
  transform: translateY(4px);
}
.setup-btn {
  background: $primary;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  color: #ffffff;
  height: 45px;
  border-radius: 0;
}

#cityDiv {
  display: none;
}

.prefix-right {
  border-radius: 0;
}
.radio-div {
  border-radius: 0 !important;
  background: #ffffff;
  height: 44px;
  border: 0.5px solid #445db6;
  box-sizing: border-box;
  box-shadow: none;
  border-radius: 5px;
}

.radio-btn {
  padding-top: 0.7rem;
  display: flex;
  justify-content: space-evenly;
}

.errorHeader {
  font-family: $fontFamily;
  font-weight: normal;
  font-size: 17px;
  list-style: none;
  text-align: center;
  margin-bottom: 0;
  // margin-left: 1em;
}
.errorMessage {
  font-family: $fontFamily;
  font-weight: normal;
  color: #dc3545;
  font-size: 14px;
  list-style: none;
  text-align: center;
  padding-inline-start: 0;
}
.error-ul {
  padding-inline-start: 0;
}

.btn-loader {
  padding: 0 !important;
  font-size: 17px !important;
  margin-left: 2rem !important;
}

// Welcome page that was moved to his components
.welcome-holder {
  padding-top: 4rem;
  padding-bottom: 3rem;
  display: none;
}

.welcome-child {
  background: $primary;
  width: 441.03px;
  height: 398.87px;
  margin: 0 auto;
}
.close-welcome-div {
  display: flex;
  justify-content: flex-end;
  position: relative;
}
.close-welcome-div > small {
  position: absolute;
  top: 1rem;
}
.welcome-body {
  padding-top: 3rem;
}
.close-welcome-icon::before {
  color: #ffffff;
  font-size: 1rem;
}
.welcome-body > p:first-child {
  font-family: $fontFamily;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 40px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  color: #ffffff;
  padding-bottom: 1rem;
}
.welcome-body > p:nth-child(3) {
  font-family: Gotham Ultra;
  font-style: italic;
  font-weight: 800;
  font-size: 35px;
  line-height: 40px;
  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;
  color: #ffffff;
  padding-top: 1rem;
}
// Welcome page ends

::-webkit-input-placeholder {
  /* Edge */
  font-family: $fontFamily;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.5);
  text-decoration-line: underline;
}

:-ms-input-placeholder {
  /* Internet Explorer */
  font-family: $fontFamily;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.5);
  // text-decoration-line: underline;
}

::placeholder {
  font-family: $fontFamily;
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 25px;
  color: rgba(0, 0, 0, 0.5);
  // text-decoration-line: underline;
}

@media screen and (min-width: 1200px) and (max-height: 640px) and (-webkit-device-pixel-ratio: 1) {
}
@media screen and (min-device-width: 1200px) and (max-device-width: 1600px) and (-webkit-min-device-pixel-ratio: 1) {
  .body-child {
    max-width: 35.46rem !important;
  }
}
</style>
